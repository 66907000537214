import { Route, Routes } from "react-router-dom";
import './style.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from "./pages/Home/Home";
import Productions from "./pages/Productions/Productions";
import OwnDeveloped from "./pages/OwnDeveloped/OwnDeveloped";
import DetailProd from "./pages/DetailProd/DetailProd";
import Team from "./pages/Team/Team";
import DetailTeam from "./pages/DetailTeam/DetailTeam";
import Contact from "./pages/Contact/Contact";
import { createContext, useState } from "react";
import DetailDeveloped from "./pages/DetailDeveloped/DetailDeveloped";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPrefix } from "./helpers/prefixHelper";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

export const language = createContext({})

function App() {
  // const lang = window.location.pathname.includes("en") ? setCurrentLanguage("en") : setCurrentLanguage('hu')
  // console.log(lang)
  const [currentLanguage, setCurrentLanguage] = useState(window.location.pathname.includes("en") ? "en" :'hu')
  const prefix = getPrefix(currentLanguage);
  const navigate = useNavigate()

  const changeLanguage = (lang) => {
    if (typeof lang === "string" && lang) {
      setCurrentLanguage(lang)
    } else if (currentLanguage === 'hu') {
      setCurrentLanguage('en')
      let stateObj = { id: "100" };
      window.history.pushState(stateObj,
        "/en", `/en${window.location.pathname}`);
      navigate(`${prefix}${window.location.pathname}`)
    } else {
      setCurrentLanguage('hu')
      let stateObj = { id: "100" };
      window.history.pushState(stateObj,
        "/hu", `${window.location.pathname.replace('/en', "")}`);
    }
  }

  // useEffect(() => {
  //   changeLanguage(window.localStorage.getItem('CURRENT_LANGUAGE'))
  // }, [])

  // useEffect(() => {
  //   window.localStorage.setItem('CURRENT_LANGUAGE', currentLanguage);
  // }, [currentLanguage])

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div>
      <language.Provider value={{ currentLanguage, setCurrentLanguage, changeLanguage, getPrefix }}>
        <Header />
        <Routes >
            <Route exact path={`/en/`} element={<Home/>}/>
            <Route path={`/en/produkciok`} element={<Productions/>}/>
            <Route path={`/en/produkciok/:id`} element={<DetailProd/>}/>
            <Route path={`/en/sajat-fejlesztesek`} element={<OwnDeveloped/>}/>
            <Route path={`/en/sajat-fejlesztesek/:id`} element={<DetailDeveloped/>}/>
            <Route path={`/en/csapat`} element={<Team/>}/>
            <Route path={`/en/csapat/:id`} element={<DetailTeam/>}/>
            <Route path={`/en/kapcsolat`} element={<Contact/>}/>
            <Route exact path={`/`} element={<Home/>}/>
            <Route path={`/produkciok`} element={<Productions/>}/>
            <Route path={`/produkciok/:id`} element={<DetailProd/>}/>
            <Route path={`/sajat-fejlesztesek`} element={<OwnDeveloped/>}/>
            <Route path={`/sajat-fejlesztesek/:id`} element={<DetailDeveloped/>}/>
            <Route path={`/csapat`} element={<Team/>}/>
            <Route path={`/csapat/:id`} element={<DetailTeam/>}/>
            <Route path={`/kapcsolat`} element={<Contact/>}/>
        </Routes>
        <Footer />
      </language.Provider>
    </div>
  );
}

export default App;
